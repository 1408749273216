


































import Vue from 'vue'
import { data2tree } from '../../utils/tree'

export default Vue.extend({
  props: {
    id: String,
  },
  data() {
    return {
      model: {
        name: '',
        menus: [],
        permissions: [],
      },
      defaultProps: {
        multiple: true,
        value: 'id',
        label: 'name',
        emitPath: false,
        checkStrictly: true,
      },
      menus: [],
      permissions: [],
    }
  },
  created() {
    this.id && this.fetch()
    this.getMenus()
    this.getPermissions()
  },
  methods: {
    async fetch() {
      const { data: role } = await this.$http.get(`roles/${this.id}`)
      if (role.menus && role.menus.length) {
        role.menus = role.menus.map((i: any) => i.id)
      }
      if (role.permissions && role.permissions.length) {
        role.permissions = role.permissions.map((i: any) => i.id)
      }
      this.model = Object.assign(this.model, role)
    },
    async getMenus() {
      const res = await this.$http.get('menus?paginate=0&order=asc')
      this.menus = data2tree(res.data)
    },
    async getPermissions() {
      const res = await this.$http.get('permissions?paginate=0&order=asc')
      this.permissions = data2tree(res.data)
    },
    async save() {
      if (this.id) {
        await this.$http.put(`roles/${this.id}`, this.model)
      } else {
        await this.$http.post('roles', this.model)
      }
      this.$router.push('/role/list')
      this.$message.success('保存成功')
    },
  },
})
